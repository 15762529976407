import { Turbo } from "@hotwired/turbo-rails";

type ConfirmDialogConfig = {
  title: string;
  icon?: "warning" | "success";
  text?: string;
  showCancelButton: boolean;
  confirmButtonColor: string;
  cancelButtonColor: string;
  cancelButtonText: string;
  confirmButtonText: string;
  reverseButtons: boolean;
};

const createConfirmDialog = (config: ConfirmDialogConfig): HTMLDivElement => {
  const dialog = document.createElement("div");
  dialog.className = "relative z-40 ease-out duration-300 opacity-100";
  dialog.setAttribute("aria-labelledby", "modal-title");
  dialog.setAttribute("role", "dialog");
  dialog.setAttribute("aria-modal", "true");

  const iconMarkup =
    config.icon === "warning"
      ? `
    <div class="mx-auto size-24 flex items-center justify-center text-orange-300">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
        <path d="M12 8v4"></path>
        <path d="M12 16h.01"></path>
      </svg>
    </div>`
      : `
    <div class="mx-auto flex size-24 items-center justify-center rounded-full bg-green-100">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path d="M5 12l5 5l10 -10"></path>
      </svg>
    </div>`;

  dialog.innerHTML = `
    <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true" id="modal-backdrop"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto" id="modal-container">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div>
            ${iconMarkup}
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold text-gray-900" id="modal-title">${config.title}</h3>
              ${config.text ? `<p class="mt-2 text-sm text-gray-500">${config.text}</p>` : ""}
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            ${config.showCancelButton ? `<button type="button" class="cancel-button inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">${config.cancelButtonText}</button>` : ""}
            <button type="button" class="confirm-button inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500">${config.confirmButtonText}</button>
          </div>
        </div>
      </div>
    </div>
  `;

  dialog.addEventListener("click", (event: MouseEvent) => {
    if (
      event.target &&
      event.target instanceof HTMLElement &&
      event.target.tagName != "BUTTON"
    ) {
      document.body.removeChild(dialog);
    }
  });
  return dialog;
};

const showConfirmDialog = (config: ConfirmDialogConfig): Promise<boolean> => {
  return new Promise((resolve) => {
    const dialog = createConfirmDialog(config);
    document.body.appendChild(dialog);

    const confirmButton = dialog.querySelector(
      ".confirm-button",
    ) as HTMLButtonElement;
    const cancelButton = dialog.querySelector(
      ".cancel-button",
    ) as HTMLButtonElement | null;

    confirmButton.addEventListener("click", () => {
      document.body.removeChild(dialog);
      resolve(true);
    });

    if (cancelButton) {
      cancelButton.addEventListener("click", () => {
        document.body.removeChild(dialog);
        resolve(false);
      });
    }
  });
};

const newConfirmMethod = async (
  message: string,
  formElement: HTMLElement,
): Promise<boolean> => {
  const category = formElement.dataset.category || "default";
  const description = formElement.dataset.description || "";
  const cancelButtonText = formElement.dataset.cancelButtonText || "キャンセル";
  const confirmButtonText = formElement.dataset.confirmButtonText || "実行する";
  const icon = "warning";
  const confirmButtonColor = "#ef4444";

  const config: ConfirmDialogConfig = {
    title: message,
    icon,
    text: description,
    showCancelButton: true,
    confirmButtonColor,
    cancelButtonColor: "#f1f5f9",
    cancelButtonText,
    confirmButtonText,
    reverseButtons: true,
  };

  return await showConfirmDialog(config);
};

Turbo.setConfirmMethod(newConfirmMethod);
